<template>
    <v-container ma-0 fluid>
        <h1 class="title">Sample search</h1>

        <Help>Here, you can search for any sample in the system. Enter the allele number(s) at each locus separated by a comma.
            The
            <v-btn x-small color="grey">
                <v-icon>mdi-keyboard-tab</v-icon>
                Tab
            </v-btn>
            button on your keyboard will take you to the next field. You can press
            <v-btn x-small color="grey">
                <v-icon>mdi-keyboard-return</v-icon>
                Return
            </v-btn>
            or
            <v-btn x-small color="blue">Start</v-btn>
            to begin the search.
            <br/>
            Use
            <v-btn x-small color="red darken-3">Clear</v-btn>
            to erase the search terms.
        </Help>

        <section class="locusapp">
            <Error :error="error"/>
            <v-form @submit.prevent="search">
                <v-row dense class="pb-0 mb-0">
                    <v-col v-for="(locus, i) in loci" :key="i"
                           cols="24"
                           sm="2"
                           class="pb-0 mb-0"
                    >
                        <v-text-field :label="locus.name" v-model="locus.search" dense clearable single-line>
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-btn type="submit" label="Search" color="blue" outlined>Start</v-btn>
                <v-btn @click="clearSearch" color="red darken-3" text>Clear</v-btn>
            </v-form>
        </section>
        <v-container fluid>
            {{ queryMessage }}
            <v-data-table
                    :headers="headers"
                    :items="data"
                    :items-per-page="10"
                    :loading="loading"
                    class="elevation-1"
            >
                <template v-slot:item="{ item }">
                    <SampleBodyTemplate :item="item" :loci="loci"/>
                </template>

            </v-data-table>
        </v-container>
    </v-container>
</template>

<script>
    import {default as locusApi} from '@/apis/locus.js'
    import {default as sampleApi} from '@/apis/sample.js'
    import {default as sampleTable} from '@/components/sampleTable.js'
    import {default as SampleBodyTemplate} from '@/views/sample/SampleBodyTemplate.vue'
    import Error from '@/views/Error.vue'
    import Help from '@/views/Help.vue'

    const SampleSearch = {
        name: 'SampleSearch',
        components: {Help, Error, SampleBodyTemplate},
        props: {
            activeUser: Object
        },
        // app initial state
        data: function () {
            return {
                queryMessage: "",
                loci: [],
                data: [],
                headers: [],
                error: null,
                loading: false
            }
        },
        mounted() {
            this.loadLoci()
        },
        methods: {
            clearSearch() {
                for (const searchLocus of this.loci) {
                    searchLocus.search = ""
                }
                this.data = []
                this.queryMessage = ""
                this.error = ""
            },
            loadLoci() {
                this.loading = true;
                locusApi.getAll()
                    .then(response => {
                        let {headers, loci} = sampleTable.generateHeadersLoci(response)
                        this.headers = headers
                        this.loci = loci
                    })
                    .catch(error => {
                        this.$log.debug(error)
                        this.error = "Failed to load loci"
                        this.$store.dispatch('updateUser')
                    })
                    .finally(() => this.loading = false)
            },

            search() {
                this.loading = true
                const queryStart = performance.now()
                sampleApi.searchMatchingLoci(this.loci.filter(
                    function (locus) {
                        return (locus.search !== "") && (locus.search !== null)
                    }
                ).map(
                    function (locus) {
                        return {id: locus.id, search: locus.search}
                    }
                )).then(response => {
                    this.queryMessage = `This search took ${performance.now() - queryStart} ms`
                    this.error = ""
                    this.data = sampleTable.generateItemsFromSearch(response.data, this.loci)
                }).catch(error => {
                    this.$log.debug(error)
                    if (error.response === undefined) {
                        this.error = "There was a network error, you may have to logout/login again sorry."
                    } else
                    if (error.response.status === 400)
                        this.error = "You need at least one search parameter."
                    else
                        this.error = "Impossible to access the server."
                    this.$store.dispatch('updateUser')
                }).finally(() => this.loading = false)

            }
        }
    }
    export default SampleSearch
</script>

<style>
    tbody tr:nth-of-type(odd) {
        background-color: rgba(0, 0, 50, .25);
    }

    tbody tr td {
        padding: 0 0 0 0 !important;
    }

    thead tr th {
        padding: 0 1em 0 1em !important;
    }

    .wider {
        padding: 1em !important;
    }

</style>