<template>
        <tr>
            <td class="wider">{{ item.name }}</td>
            <td class="wider">{{ item.creation_date }}</td>
            <td v-for="locus in loci" :key="locus.id">
                <div v-if="item.loci[locus.id]">
                <v-chip small :color="item.loci[locus.id].value1Selected">
                    {{ item.loci[locus.id].value1 }}
                </v-chip>
                <br/>
                <v-chip small :color="item.loci[locus.id].value2Selected"
                        v-if="item.loci[locus.id].value2">
                    {{ item.loci[locus.id].value2 }}
                </v-chip>
                </div>
            </td>
        </tr>
</template>

<script>
    export default {
        name: "SampleBodyTemplate",
        props: {item: Object, loci: Array},
    }
</script>

<style scoped>

</style>