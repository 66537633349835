<template>
    <div>
        <v-alert type="error" v-if="error" class="error">
            <slot/>
            {{ error }}
        </v-alert>
    </div>
</template>

<script>
    export default {
        name: "Help",
        props: {error: String},
        methods: {

        }
    }
</script>

<style scoped>

</style>