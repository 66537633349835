import axios from 'axios'

const SERVER_URL = 'api/sample';

const instance = axios.create({
    baseURL: SERVER_URL,
    timeout: 10000
});

export default {
    // (R)ead
    getAll: () => instance.get('', {
        transformResponse: [function (data) {
            return data ? JSON.parse(data)._embedded.samples : data;
        },]
    }),

    // (R)ead Full with pagination
    getAllFullPaging: (page, size, sortBy, sortDesc) => {
        let params = { page: page, size: size, projection: 'full'}
        if (sortBy !== undefined) {
            params.sort = sortBy
            if (sortDesc === true) {
                params.sort += ",desc"
            } else {
                params.sort += ",asc"
            }
        }

        return instance.get('', {
                params: params
            }
        )
    },
    // (D)elete
    removeForId: (id) => instance.delete('/' + id),

    searchMatchingLoci: (locusList) => instance.post('/search', {
            data: locusList
        })
}
