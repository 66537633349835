/**
 * Search if the /value/ for /locus/ is present in the search fields.
 * @param locus
 * @param value
 * @returns {boolean}
 */
function isSelected(loci, locus, value) {
    for (const searchLocus of loci) {
        if (searchLocus.id === locus && searchLocus.search !== null) {
            const lociValues = searchLocus.search.split(",")
            if (lociValues.some(function (lociValue) {
                return value.toUpperCase() === lociValue.toUpperCase()
            }) === true) return true
        }
    }
    return false
}

export default {
    generateHeadersLoci: (response) => {
        const headers = [{'text': 'Name', value: 'name', align: 'center'},
            {'text': 'Date', value: 'creation_date', align: 'center'}]
        const loci = []

        for (const element of response.data) {
            headers.push(
                {
                    text: element.name,
                    align: 'center',
                    sortable: false,
                    value: element.id,
                },
            )
            loci.push(
                {
                    id: element.id,
                    name: element.name,
                    search: ""
                },
            )
        }

        return {headers: headers, loci: loci}
    },

    generateItemsFromSearch: (data, loci) => {
        let items = []
        for (const item of data) {
            let row = {}
            for (const locusall of item.loci) {
                // If we already have info on that locus, add a separating "/"
                let value = locusall.index
                if (locusall.repeat !== "") {
                    value += `.${locusall.repeat}`
                }

                if (row[locusall.locus_id]) {
                    row[locusall.locus_id].value2 = value
                    row[locusall.locus_id].value2Selected = isSelected(
                        loci, locusall.locus_id, value
                    ) ? "red" : ""
                } else {
                    row[locusall.locus_id] = {locus: locusall.locus_id, value1: value}
                    row[locusall.locus_id].value1Selected = isSelected(
                        loci, locusall.locus_id, value
                    ) ? "blue" : ""
                }

            }
            items.push({name: item.name, creation_date: item.creation_date, loci: row})
        }
        return items
    }
}